.loading-content{
    height: 500px;
}

.mainContent{
    margin-top: 2rem;
    /* min-height: 470px; */
}

.cursor-pointer{
    cursor: pointer;
}

.brand-logo{
    font-size: 1.5rem !important;
}

.mt-1{
    margin-top: 1rem;
}



.btn-floating{
    float: right;
}

@media (max-width: 600px) {
    .text-button {
      font-size: 9px;
    }
  }



.blog .material-icons{
    color:  white; 
}


.blog section {
    padding-top: 3rem;
    border-bottom: none;
}



.blog .material-icons{
    color:  white; 
}

.blog{
    background-image: none !important;
    height: auto !important ;
}

.blog nav ul a {
    color: white !important;
}
