body {
    font-family: 'Quicksand', sans-serif;
    font-display: swap;
}

header{
    height: 400px;
    /* background-image: url(./images/header.png); */
    background-size: cover;
    border-bottom: 1px solid ;
    background: #0f7bff;
}

header .valign-wrapper{
    min-height: 200px;
}

.blueNav{
    /* background-color: #92a8c3 !important; */
}

.capitalize{
    text-transform: capitalize;
}

header nav{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

}

#navMenu{
    border-bottom: 1px solid #0f7bff !important;
    min-height: 75px !important;
    background: radial-gradient(black, transparent);
}

nav ul a{
    color: white !important;
}

.blueNav ul a{
    color: white9e9e9e !important;
}


.cabeceraDesc{
    width: 100% !important;
}

.cabeceraDesc h6{
    font-weight: bold;
}


section {
    padding-top: 3rem;
    border-bottom: 1px solid #d1d1d1;
}

section img{
    border-radius: 10px !important;
}


section p{
    color:  #9e9e9e !important;
}


.titleSection{
    font-weight: bold !important;
    font-size: 20px;
    color: black;
}

section .card-image .icon{
    font-size: 50px !important;
    height: 100px;
    width: 100px;
    padding-top: 2rem;
    color: #0f7bff !important
}

section .card-content{
    padding-top: 1px !important;
    
}

section .card{
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}


.titleCard{
    font-size: 18px !important;
    font-weight: bold !important;
    color: black !important;
}

#services{
    min-height: 400px;
    background-color: #f5fdfe !important;
}

#services .card{
    background: #f5fdfe !important;
}


#services .card:hover .icon{
    border-radius: 10px;
    border-bottom: 5px solid;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

#services .card:hover .titleCard{
    color: #0f7bff !important
}


.imageContact{
    height: 400px !important;
}


.page-footer{
    background-color: #0f7bff !important;
}

.ulFooter{
    font-size: 30px !important;
}

.ml-1{
    margin-left: 1rem !important;
}


.input-field input:focus + label {
    color: #0f7bff !important;
  }
  /* label underline focus color */
  .row .input-field input:focus {
    border-bottom: 1px solid #0f7bff !important;
    box-shadow: 0 1px 0 0 #0f7bff !important
  }

  .input-field textarea:focus + label {
    color: #0f7bff !important;
  }
  /* label underline focus color */
  .row .input-field textarea:focus {
    border-bottom: 1px solid #0f7bff !important;
    box-shadow: 0 1px 0 0 #0f7bff !important
  }

 #imgLogo{
    max-height: 80px !important;
  }

  #imgLogoMin{
    max-height: 55px !important;
  }

  .user-view{
    height: 128px !important;
    z-index: 1 !important;
  }

  .backBlue{
      background-color: #0f7bff;
  }

  .imgSideNav{
    height: 100px !important;
    margin-top: 1rem !important;
  }

  .sidenav .user-view .background {
   text-align: center !important;
}

.titleInnato{
    font-size: 30px;
    font-weight: bold;
}

.owl-carousel{
margin-top: 1rem !important;
}

#contact{
    background-color: #f5fdfe !important;
}

.img-min {
    margin-top: .5rem !important;
}