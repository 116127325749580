h1{
    text-transform: capitalize;
    font-weight: bold;
    font-size: 40px;
}

.subText{
    font-size: 10px;
}

.BlogHeader{
    height: 210px !important;
}

.blogContent{
    margin-top:  -10rem !important;
}

#postContent{
    padding-top:  .5rem !important;
}

#postContent img{
    max-width: 100% !important;
    height: auto !important;
}

.blogContent h1{
    font-size: 30px !important;
}

.shareButton{
    font-size: 40px!important;
}

.shareText{
    font-size: 25px !important;
}

#postContent h3{
    font-size: 20px !important;
}

#postContent strong{
    font-weight: bold !important;
}

#postContent p{
    color: black !important;
}

#postContent code{
    background: black !important;
    color: white !important; 
    padding: 5px !important;
}


#postContent blockquote p{
   color: #9e9e9e!important;
}


.titlePost{
    font-weight: bold;
   text-transform: capitalize;
   color: black;
}

.ulPostList{
    min-height: 324px !important;
}


.postItem:hover{
    background-color:#f3f3f3;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

